import { useReducer, useRef } from 'react';

import sortDataByProperty from 'legacy/shared/v2/utilities/sortDataByProperty';
import { tableActions } from 'legacy/shared/v2/components/controls/WcpTable/tableActions';
import { getSubstringIndex } from 'legacy/shared/v2/utilities/getSubstringIndex';

const filterTableData = (searchText, tableSourceData, searchEnabledColumns) => {
  return tableSourceData
    .map((row) => {
      let searchMatchSubstrings = {};

      let applicableRows = Object.entries(row).filter(
        ([prop, value]) => typeof value === 'string' && searchEnabledColumns.includes(prop),
      );

      applicableRows.forEach(([prop, value]) => {
        const matchIndex = getSubstringIndex({ originalString: value, substring: searchText });
        if (matchIndex !== -1) {
          searchMatchSubstrings[prop] = {
            first: value.substring(0, matchIndex),
            match: value.substring(matchIndex, matchIndex + searchText.length),
            last: value.substring(matchIndex + searchText.length),
          };
        } else {
          searchMatchSubstrings[prop] = null;
        }
      });

      return {
        ...row,
        searchMatchSubstrings,
      };
    })
    .filter((row) => {
      return Object.values(row.searchMatchSubstrings).some((match) => match !== null);
    });
};
const useTableReducer = ({
  tableSourceData,
  searchEnabledColumns,
  defaultPageSize,
  defaultSortProperty,
  defaultSortOrder,
}) => {
  const oldTableData = useRef(tableSourceData);
  const initialState = {
    currentPageSize: defaultPageSize,
    currentPageIndex: 0,
    searchResults: tableSourceData,
    totalPageCount: getTotalPageCount({ sourceData: tableSourceData, pageSize: defaultPageSize }),
    pagedResults: getPagedResults({ sourceData: tableSourceData, pageSize: defaultPageSize }),
    currentSortProperty: defaultSortProperty,
    currentSortOrder: defaultSortOrder,
  };

  const tableReducerFunction = (state, action) => {
    switch (action.type) {
      case tableActions.RESET_DISPLAY_DATA: {
        const { tableSourceData, searchText } = action.payload;

        let displayData =
          searchText === ''
            ? tableSourceData
            : filterTableData(searchText, tableSourceData, searchEnabledColumns);

        const sortedDisplayData = sortDataByProperty({
          sourceData: displayData,
          sortProperty: defaultSortProperty,
          sortOrder: defaultSortOrder,
        });

        const dataChanged = sortedDisplayData?.length !== oldTableData.current.length;
        if (dataChanged) {
          oldTableData.current = sortedDisplayData;
          let resetState = {
            currentPageSize: defaultPageSize,
            currentPageIndex: 0,
            searchResults: sortedDisplayData,
            totalPageCount: getTotalPageCount({
              sourceData: sortedDisplayData,
              pageSize: defaultPageSize,
            }),
            pagedResults: getPagedResults({
              sourceData: sortedDisplayData,
              pageSize: defaultPageSize,
            }),
            currentSortProperty: defaultSortProperty,
            currentSortOrder: defaultSortOrder,
          };

          return {
            ...state,
            ...resetState,
          };
        } else {
          return state;
        }
      }

      case tableActions.SELECT_PAGE_SIZE: {
        const { pageSize } = action.payload;
        const { searchResults } = state;

        return {
          ...state,
          pagedResults: getPagedResults({ sourceData: searchResults, pageSize }),
          currentPageSize: pageSize,
          currentPageIndex: 0,
          totalPageCount: getTotalPageCount({ sourceData: searchResults, pageSize }),
        };
      }
      case tableActions.SELECT_PAGE: {
        const { selectedPageIndex } = action.payload;
        const { searchResults, currentPageSize } = state;
        return {
          ...state,
          currentPageIndex: selectedPageIndex,
          pagedResults: getPagedResults({
            sourceData: searchResults,
            startingPage: selectedPageIndex * currentPageSize,
            pageSize: currentPageSize,
          }),
        };
      }
      case tableActions.SORT: {
        const { sortOrder, sortProperty } = action.payload;
        const { searchResults, currentPageSize, currentPageIndex } = state;

        const sortedSearchResults = sortDataByProperty({
          sourceData: searchResults,
          sortProperty,
          sortOrder,
        });
        return {
          ...state,
          pagedResults: getPagedResults({
            sourceData: sortedSearchResults,
            startingPage: currentPageIndex * currentPageSize,
            pageSize: currentPageSize,
          }),
          totalPageCount: getTotalPageCount({
            sourceData: sortedSearchResults,
            pageSize: currentPageSize,
          }),
          currentSortOrder: sortOrder,
          currentSortProperty: sortProperty,
        };
      }
      case tableActions.SEARCH: {
        const { searchText } = action.payload;
        const { currentPageSize } = state;
        oldTableData.current = tableSourceData;
        let displayData =
          searchText === ''
            ? tableSourceData
            : filterTableData(searchText, tableSourceData, searchEnabledColumns);

        return {
          ...state,
          searchResults: displayData,
          pagedResults: getPagedResults({ sourceData: displayData, pageSize: currentPageSize }),
          totalPageCount: getTotalPageCount({ sourceData: displayData, pageSize: currentPageSize }),
        };
      }
      default: {
        console.log('default action', action);
        return state;
      }
    }
  };

  const [state, dispatch] = useReducer(tableReducerFunction, initialState);
  const {
    currentSortOrder,
    currentSortProperty,
    currentPageSize,
    currentPageIndex,
    totalPageCount,
    searchResults,
    pagedResults,
  } = state;

  return {
    // state
    currentSortProperty,
    currentSortOrder,
    currentPageSize,
    currentPageIndex,
    totalPageCount,
    searchResults,
    pagedResults,

    // actions - TODO - refactor to use provider
    tableDispatch: dispatch,
  };
};

export default useTableReducer;
const getPagedResults = ({ sourceData, startingPage = 0, pageSize }) => {
  if (!sourceData) return [];
  return sourceData.slice(startingPage, pageSize + startingPage);
};

const getTotalPageCount = ({ sourceData, pageSize }) => {
  if (!sourceData) return 0;
  return Math.ceil(sourceData.length / pageSize);
};
